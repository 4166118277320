import React, { useEffect, useState } from "react";
import { useDocumentStore } from "../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import restrictedContent from "../../../assets/images/restricted-content.png";
import pdfThumbnail from "../../../assets/images/pdf.png";
import { Box, Button, Modal, Typography } from "@mui/material";
import axios from "axios";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import IMAGES from "../../../assets/images";

type Props = {
  uuid: string;
};

const ImageComponent: React.FC<Props> = ({ uuid }) => {
  const { getProgramDocs, url } = useDocumentStore();
  const { isBlurred } = useEnrollmentMainStore();
  const [open, setOpen] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [rotation, setRotation] = useState(0); // Rotation state

  const handleOpen = () => {
    setOpen(isBlurred ? false : true);
  };
  const handleClose = () => {
    setOpen(false);
    setRotation(0); // Reset rotation on modal close
  };

  const handleRotate = () => setRotation((prev) => prev + 90); // Rotate by 90 degrees

  useEffect(() => {
    if (uuid) {
      getProgramDocs(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    const updatePdfStatus = async (imageUrl: string) => {
      try {
        const response = await axios.get(imageUrl, { responseType: "blob" });
        const contentType = response.headers["content-type"];
        setIsPdf(contentType === "application/pdf");
      } catch (error) {
        console.error("Error checking file type:", error);
        setIsPdf(false);
      }
    };

    if (uuid && url.length > 0) {
      const imageUrl = findUrl(uuid);
      updatePdfStatus(imageUrl);
    }
  }, [uuid, url]);

  const findUrl = (imageUuid: string) => {
    const imageURL = url.find((item) => item.uuid === imageUuid);
    return (
      imageURL?.url ||
      "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930%27"
    );
  };

  const renderContent = () => {
    if (isPdf) {
      return (
        <Box>
          <img
            src={pdfThumbnail}
            alt="PDF Thumbnail"
            height="400px"
            width="600px"
            style={{ objectFit: "cover", cursor: "pointer" }}
            onClick={handleOpen}
          />
          <Typography variant="caption" align="center" display="block">
            PDF Preview Available
          </Typography>
        </Box>
      );
    }

    return (
      <img
        src={isBlurred ? restrictedContent : findUrl(uuid)}
        alt="Preview"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensures the image fits within the box
          cursor: "pointer",
        }}
        onClick={handleOpen}
      />
    );
  };

  return (
    <Box
      width="100%"
      height="200px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {renderContent()}

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "600px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          {isPdf ? (
            <iframe
              src={findUrl(uuid)}
              title="PDF Preview"
              style={{
                width: "100%",
                height: "90vh",
                border: "none",
              }}
            />
          ) : (
            <TransformWrapper>
              {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                  <TransformComponent>
                    <img
                      src={findUrl(uuid)}
                      alt="Zoomable Preview"
                      style={{
                        transform: `rotate(${rotation}deg)`,
                        maxHeight: "80vh",
                        maxWidth: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </TransformComponent>
                  <Box mt={2} display="flex" gap={2}>
                    <Button onClick={() => zoomIn()} variant="contained">
                      Zoom In
                    </Button>
                    <Button onClick={() => zoomOut()} variant="contained">
                      Zoom Out
                    </Button>
                    <Button onClick={() => resetTransform()} variant="outlined">
                      Reset
                    </Button>
                    <Button onClick={() => handleRotate()} variant="outlined">
                      Rotate
                    </Button>
                  </Box>
                </>
              )}
            </TransformWrapper>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default ImageComponent;
